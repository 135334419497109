'use client';

import cn from 'classnames';
import { format } from 'date-fns';
import { ru, es, de, enUS } from 'date-fns/locale';
import he from 'he';
import Link from 'next/link';
import type { MouseEventHandler, ReactNode } from 'react';

import { useState, useEffect } from 'react';
import { ENV } from '@shared/config';
import { Locale } from '@shared/libs';
import { Complexity, Toggle } from '@shared/ui';

import { getCurrency } from '../../../../app/currency';
import styles from '../../card.module.css';
import { Preview, Instruments, Musicians, Variants } from '../../components';
import stylesNoteWithPrice from './basket-note.module.css';

interface Props {
  id: string;
  title: string;
  url: string;
  price: string;
  isempty?: boolean;
  arrangement: {
    code: string;
    title: string;
  };
  img: {
    src: string;
    src_256_362: string;
  };
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  musicians: {
    title: string;
  }[];
  PRICE: {
    CURRENCY: string;
    PRINT_VALUE: string;
    VALUE: number;
    VARIANT: 'BASE' | 'MIDI';
  };
  ActionSlot: () => ReactNode;
  ContentSlot: () => ReactNode;
  locale: Locale;
  isExpress: boolean;
  toggleExpress: () => void;
  isEmpty: boolean;
}

function getWorkdayOffset(workdays: number): string {
  const date = new Date();
  let offset = 0;

  // Loop while the offset is less than the desired number of workdays
  while (offset < workdays) {
    date.setDate(date.getDate() + 1); // Add one day
    const day = date.getDay(); // Get the day of the week (0-6)

    // Check if the day is a weekend
    if (day === 0 || day === 6) {
      // eslint-disable-next-line no-continue
      continue; // Skip weekends
    }

    // eslint-disable-next-line no-plusplus
    offset++; // Increment the offset only if it's a weekday
  }

  return date.toISOString();
}

const DATE_LOCALE = {
  ru,
  de,
  en: enUS,
  es,
};

const getDate = (date: string) => {
  try {
    return format(new Date(date), 'd MMMM', {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      locale: DATE_LOCALE[ENV.LANG],
    });
  } catch (e) {
    return '';
  }
};

export const BasketNote = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id,
  url,
  img,
  title,
  musicians,
  arrangement,
  complexity,
  price,
  ContentSlot,
  ActionSlot,
  isempty,
  PRICE: { PRINT_VALUE, VARIANT },
  locale,
  isExpress,
  toggleExpress,
  isEmpty,
}: Props) => {
  const [expressInfo, setExpressInfo] = useState<{
    show: boolean;
    top: number;
    left: number;
  }>({
    show: false,
    top: 0,
    left: 0,
  });

  const handleExpressMouseEnter: MouseEventHandler = (event) => {
    if (event.target) {
      const { top, left } = (event.target as Element).getBoundingClientRect();
      setExpressInfo({
        show: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        left: left + (event.target as Element).offsetWidth,
        top,
      });
    }
  };
  const handleExpressMouseLeave = () => {
    setExpressInfo({
      show: false,
      left: 0,
      top: 0,
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', handleExpressMouseLeave);
    return () => {
      document.removeEventListener('scroll', handleExpressMouseLeave);
    };
  }, []);

  const [costPrice, setCostPrice] = useState();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currencyCookie = await getCurrency();

      setCostPrice(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        locale[`SBB_BASKET_EXPRESS_PRICE_${currencyCookie?.value}`] ||
          locale[`SBB_BASKET_EXPRESS_PRICE_${ENV.DEFAULT_CERRENCY}`],
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn(styles.cardHorisontal, styles.cardWhite, stylesNoteWithPrice.indent)}>
      <p className={stylesNoteWithPrice.price}>{price}</p>
      <Complexity complexity={complexity} className={stylesNoteWithPrice.complexity} />
      <Preview src={img.src_256_362 || img.src} alt={title} href={url} target="_blank" />

      <div className={styles.contentHorisontal}>
        <div className={cn(styles.contentTop, stylesNoteWithPrice.content)}>
          <Musicians url={url} musicians={musicians} />
          <Link href={url} className={cn(styles.title, stylesNoteWithPrice.cardTitle)}>
            {he.decode(title)}
          </Link>
          {!isEmpty ? (
            <p className={cn(stylesNoteWithPrice.price)}>{he.decode(PRINT_VALUE)}</p>
          ) : null}
          <div className={stylesNoteWithPrice.instruments}>
            <Instruments instruments={[arrangement]} />
            {isempty && process.env.NEXT_PUBLIC_ORDER_FLAG === 'true' && (
              <div className={stylesNoteWithPrice.isemptyDate}>
                {getDate(getWorkdayOffset(isExpress ? 3 : 7))},{' '}
                {locale.SBB_BASKET_ITEM_DOWNLOAD || 'SBB_BASKET_ITEM_DOWNLOAD'}
              </div>
            )}
          </div>

          {isempty && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={stylesNoteWithPrice.express} onClick={toggleExpress}>
                <div className={stylesNoteWithPrice.expressToggle}>
                  <Toggle toggleName={`express-${id}`} checked={isExpress} />
                  <span className={stylesNoteWithPrice.expressLabel}>
                    {locale.SBB_BASKET_EXPRESS_LABEL}
                  </span>
                </div>
                <span className={stylesNoteWithPrice.expressPrice}>{costPrice}</span>
              </div>
              <div>
                <p
                  className={stylesNoteWithPrice.expressInfoLabel}
                  onMouseEnter={handleExpressMouseEnter}
                  onMouseMove={handleExpressMouseEnter}
                  onMouseLeave={handleExpressMouseLeave}
                >
                  {locale.SBB_BASKET_EXPRESS_INFO_LABEL}
                </p>
                {expressInfo.show && (
                  <div
                    style={{
                      left: `${expressInfo.left}px`,
                      top: `${expressInfo.top}px`,
                    }}
                    className={stylesNoteWithPrice.expressInfoText}
                  >
                    {locale.SBB_BASKET_EXPRESS_INFO_TEXT}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className={stylesNoteWithPrice.buttons}>
          <ContentSlot />
          <ActionSlot />
        </div>
        <div className={cn(styles.variants, stylesNoteWithPrice.variants)}>
          <Variants variants={{ [VARIANT]: VARIANT }} />
        </div>
      </div>
    </div>
  );
};
