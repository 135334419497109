import Image from 'next/image';
import Link from 'next/link';
import { linkIcon } from '@shared/assets';

import styles from './btn-link.module.css';

type Props = {
  href: string;
};

export const BtnLink = ({ href }: Props) => {
  return (
    <Link href={href} target="_blank" className={styles.btnLink} prefetch={false}>
      <Image src={linkIcon} alt="link" width={28} height={28} />
    </Link>
  );
};
