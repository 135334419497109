import Link from 'next/link';
import styles from './musicians.module.css';

type Props = {
  musicians: {
    title: string;
  }[];
  url?: string;
};

export const Musicians = ({ musicians, url }: Props) => {
  return url ? (
    <Link href={url} className={styles.musicians}>
      {musicians.map((musician) => musician.title).join(', ')}
    </Link>
  ) : (
    <p className={styles.musicians}>{musicians.map((musician) => musician.title).join(', ')}</p>
  );
};
