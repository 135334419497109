'use client';

import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import { useState, useLayoutEffect } from 'react';

import { parseMusicians, useFavourites } from '@shared/libs';
import { Complexity, Int4, Int3 } from '@shared/ui';

import { Preview, Instruments } from '../../components';
import stylesGridNote from './grid-note.module.css';

interface Props {
  id: number;
  title: string;
  url: string;
  img: {
    src: string;
    src_256_362: string;
  };
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  musicians: {
    title: string;
    url: string;
  }[];
  views: number;
  vote_count: number;
  likes: number;
  rating: number;
  favorite?: boolean;
  // FavoriteIcon: (props: { id: number; favorite: boolean }) => ReactNode;
  mobileSpace?: boolean;
  className?: string;
  arrangement?: {
    id: string;
    title: string;
  };
  altText?: string;
}

export const GridNote = ({
  id,
  url,
  img,
  title,
  musicians,
  complexity,
  vote_count,
  likes,
  views,
  rating,
  mobileSpace,
  className,
  arrangement,
  favorite,
  altText,
}: Props) => {
  const { isFavorite, toggleFavorite, countFavorite } = useFavourites({
    id,
    count: likes,
    old_favorite: favorite,
  });

  const [favStyle, setFavStyle] = useState(
    cn(stylesGridNote.favourites, {
      [stylesGridNote.isFav]: isFavorite,
    }),
  );

  useLayoutEffect(() => {
    setFavStyle(
      cn(stylesGridNote.favourites, {
        [stylesGridNote.isFav]: isFavorite,
      }),
    );
  }, [isFavorite]);

  return (
    <div
      className={cn(
        stylesGridNote.card,
        {
          [stylesGridNote.mobileSpace]: mobileSpace,
        },
        className,
      )}
    >
      <div className={stylesGridNote.bgHeader}>
        <div className={stylesGridNote.topBlock}>
          <div>
            <Complexity complexity={complexity} />
          </div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              className={favStyle}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={toggleFavorite}
            />
          </div>
        </div>
        <Preview
          href={url}
          src={img.src_256_362 || img.src}
          // ноты, аккорды музыкант - название произведения
          alt={`${altText} ${parseMusicians(musicians)} - ${title}`}
          className={stylesGridNote.previewWrap}
          height={256}
          width={181}
        />
      </div>
      <div className={stylesGridNote.info}>
        <Int4 className={stylesGridNote.rating}>
          <span className={stylesGridNote.star} />
          {`${rating} (${vote_count || 0})`}
        </Int4>
        <div className={stylesGridNote.counters}>
          <Int4 className={stylesGridNote.likesCounter}>{countFavorite || 0}</Int4>
          <Int4 className={stylesGridNote.viewsCounter}>{views || 0}</Int4>
        </div>
      </div>
      <div className={stylesGridNote.titleBlock}>
        <Link href={url} prefetch={false}>
          <Int3 className={stylesGridNote.author}>{musicians && parseMusicians(musicians)}</Int3>
          <div className={stylesGridNote.songTitle}>{he.decode(title)}</div>
        </Link>
      </div>
      <Instruments
        instruments={
          arrangement
            ? [
                {
                  code: arrangement.id,
                  title: arrangement.title,
                },
              ]
            : []
        }
      />
    </div>
  );
};
