import cn from 'classnames';

import styles from './variants.module.css';

type Props = {
  variants:
    | {
        BASE?: string;
        MIDI?: string;
      }
    | null
    | [];
  className?: string;
};

export const Variants = ({ variants, className }: Props) => {
  if (variants === null || Array.isArray(variants)) {
    return null;
  }

  return (
    <div className={cn(styles.variants, className)}>
      {'BASE' in variants && '.pdf'}
      {'MIDI' in variants && '.pdf, .midi'}
    </div>
  );
};
