import styles from './instruments.module.css';

type Props = {
  instruments: {
    code: string;
    title: string;
  }[];
};

export const Instruments = ({ instruments }: Props) => {
  return (
    <div className={styles.instruments}>
      {instruments?.map((instrument) => <span key={instrument.title}>{instrument.title}</span>)}
    </div>
  );
};
