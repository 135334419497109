import cn from 'classnames';

import Image from 'next/image';
import Link from 'next/link';
import type { HTMLAttributeAnchorTarget } from 'react';

import { ENV } from '@shared/config';

import styles from './preview.module.css';

type Props = {
  src: string;
  alt: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  contrast?: boolean;
  className?: string;
  height?: number;
  width?: number;
};

export const Preview = ({
  href,
  target,
  contrast,
  src,
  className,
  height = 135,
  width = 95,
  ...imageProps
}: Props) => {
  if (href) {
    return (
      <Link href={href} target={target} className={cn(styles.link, className)} prefetch={false}>
        <div
          className={cn(styles.wrap, className, {
            [styles.contrast]: contrast,
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            quality={100}
            src={`${ENV.STORAGE}${src}`}
            {...imageProps}
            height={height}
            width={width}
          />
        </div>
      </Link>
    );
  }
  return (
    <div
      className={cn(styles.wrap, className, {
        [styles.contrast]: contrast,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image src={`${ENV.STORAGE}${src}`} {...imageProps} height={height} width={width} />
    </div>
  );
};
